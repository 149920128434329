<template>
  <div :class="$style.root">
    <div :class="$style.badges">
      <div class="float-left"><b>{{author}}</b> {{date}}</div>
      <VChip v-if="draft" class="mr-1" outlined small>Черновик</VChip>
      <VChip v-if="actual" class="mr-1" outlined small>Актуальный</VChip>
    </div>
    <div :class="$style.body">
      <div v-if="edit">
        <VTextarea v-model="field"  rows="3" outlined auto-grow hide-details />
      </div>
      <div v-else>{{ message }}</div>
      <div :class="$style.controls">
        <template v-if="!readonly && draft && (!items || !items.length)">
          <template v-if="!edit">
            <VBtn class="mr-1" outlined small color="primary" @click="onEdit">Изменить</VBtn>
            <VBtn class="mr-1" outlined small color="error" @click="onDelete">Удалить</VBtn>
          </template>
          <template v-else >
            <VBtn class="mr-1" :disabled="!field" outlined small color="primary" @click="onSave">Сохранить</VBtn>
            <VBtn class="mr-1" outlined small color="secondary" @click="onReset">Отмена</VBtn>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';

export default {
  name: 'Comment',
  components: {
  },
  props: {
    id: { type: String },
    form: { type: String },
    type: { type: String },
    code: { type: String },
    date: { type: String },
    draft: { type: Boolean },
    actual: { type: Boolean },
    author: { type: String },
    message: { type: String },
    items: { type: Array },
    readonly: { type: Boolean },
  },
  data() {
    return {
      edit: false,
      field: '',
    };
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkomain');
    },
  },
  methods: {
    ...mapActions({
      editComment: 'pkomain/editComment',
      createComment: 'pkomain/createComment',
      fetchCommentList: 'pkomain/fetchCommentList',
      deleteComment: 'pkomain/deleteComment',
      approveComment: 'pkomain/approveComment',
    }),
    onSubmit(message) {
      const parent = this.id;
      const type = this.type;
      const code = this.code;
      const form = this.form;
      this.createComment({ form, parent, type, code, message }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий успешно отправлен',
        });
        return this.fetchCommentList({ id: form }).then((result) => {
          this.answer = false;
          return result;
        });
      })
    },
    onEdit() {
      this.edit = true;
      this.field = this.message;
    },
    onDelete() {
      const id = this.form;
      this.deleteComment({ id, comments: [{id: this.id}] }).then(() => {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий удален',
        });
        this.fetchCommentList({ id });
      });
    },
    onSave() {
      const id = this.id;
      const message = this.field;
      this.editComment({ id, message }).then(() => {
        this.edit = false;
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Комментарий обновлен',
        });
      })
    },
    onReset() {
      this.edit = false;
      this.field = this.message;
    },
  }
}
</script>

<style module lang="scss">
.root {
  border: 1px solid #dee2e6;
  padding: 10px;
  & + & {
    margin-top: 20px;
  }
  & & {
    margin-top: 10px;
    background-color: #dcffd6;
  }
}
.body {}
.badges {
  overflow: hidden;
  margin-bottom: 10px;
  text-align: right;
}
.controls {
  margin-top: 10px;
}
.answer {
  margin-top: 10px;
}
</style>
