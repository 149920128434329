<template>
  <div :class="$style.root">
    <CounterpartyCard label="Отображение пунктов анкеты">
      <VRadioGroup v-model="simple" class="my-0" hide-details>
        <VRadio label="Все" :value="false" />
        <VRadio label="С комментариями проверяющего" :value="true" />
      </VRadioGroup>
    </CounterpartyCard>
    <VForm @submit.prevent="onSave">
      <VExpansionPanels flat multiple v-model="panels">
        <CounterpartyDetailSection
            v-for="(section, code) in filtered(fields)"
            v-bind="{...section}"
            :progress="progressBySection(section)"
            :status="status"
            :readonly="readonly"
            :comments="comments"
            :hiddenKeys="hiddenKeys"
            :key="code"
            :version="version"
            :pending="pending"
            :icon="getIconForSection(code)"
        />
      </VExpansionPanels>
    </VForm>
    <VFooter app>
      <Navigation :items="navigation" />
      <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" :class="$style.loading" />
      <div class="py-3 grow d-flex">
        <VBtn class="mr-6" outlined depressed @click="$router.push({ name: 'pkomain/CounterpartyListView' })">
          <VIcon>mdi-arrow-left</VIcon>
          Назад к списку
        </VBtn>
        <template v-if="!readonly">
          <VBtn color="primary" class="mr-2" depressed :disabled="pending || !diff" @click="onSave">Сохранить</VBtn>
          <VBtn color="secondary" class="mr-2" depressed :disabled="!diff || pending" @click="onReset">Отменить</VBtn>
          <VBtn color="primary" class="order-1" depressed :disabled="diff || hasActualComments || pending" @click="onAgreement">Отправить на согласование</VBtn>
        </template>
        <template v-if="canMakeDecision">
          <VBtn color="primary" class="mr-2" depressed :disabled="!hasDraftComments || pending" @click="onRework">Доработка</VBtn>
          <VBtn color="success" class="mr-2" depressed :disabled="hasDraftComments || pending" @click="onYes">Согласовать</VBtn>
          <VBtn color="error" class="order-1" depressed :disabled="pending" @click="onNo">Отказать</VBtn>
        </template>
        <template v-if="canChangeDecision">
          <VBtn color="error" class="order-1" depressed :disabled="pending" @click="onNo">Отменить предыдущее решение</VBtn>
        </template>
        <div class="grow mr-2">
          <VProgressLinear height="36" :color="progress.current === progress.total ? 'success' : 'primary'" :value="progress.current * 100 / progress.total">
            <strong>Заполнено пунктов анкеты: {{progress.current}} из {{progress.total}}</strong>
          </VProgressLinear>
        </div>
      </div>
    </VFooter>

    <VDialog v-model="dialog" max-width="900" scrollable persistent>
      <VCard tile>
        <VToolbar flat dark color="primary">
          <VToolbarTitle class="px-2">Решение по заявке</VToolbarTitle>
          <VSpacer/>
          <VBtn icon dark @click="dialog = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText class="pt-5">
          <VCardText>
            <DecisionForm :disabled="pending" @submit="onSubmitDecision" />
          </VCardText>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import { map, get, omit, pick, isEmpty, pickBy, keys, head, find, includes, forEach, compact, concat, toNumber, reverse, set } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkomain/enums';
import CounterpartyCard from '@/components/pkomain/CounterpartyCard/CounterpartyCard';
import CounterpartyDetailSection from '@/components/pkomain/CounterpartyDetailSection/CounterpartyDetailSection';
import DecisionForm from '@/components/pkomain/DecisionForm/DecisionForm';
import Navigation from '@/components/pkomain/CounterpartyDetail/components/Navigation';
export default {
  name: 'CounterpartyDetail',
  components: {
    CounterpartyCard,
    CounterpartyDetailSection,
    DecisionForm,
    Navigation,
  },
  props: {
    id: { type: String },
    values: { type: Object },
    status: { type: String },
    comments: { type: Object },
    archive: { type: Boolean },
    version: { type: String },
    pending: { type: Boolean },
    canChangeDecision: { type: Boolean },
  },
  data() {
    return {
      simple: false,
      timer: null,
      panels: [0],
      fields: JSON.parse(JSON.stringify(this.values)),
      dialog: false,
      decision: null,
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
    readonly() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain') || this.hasRole(ROLES.SPECTATOR, 'pkomain') || this.status === STATUSES.ON_APPROVAL;
    },
    waitDecision() {
      return !toNumber(this.version) && this.status === STATUSES.WAIT_FOR_DECISION;
    },
    canMakeDecision() {
      return this.waitDecision && this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    hasActualComments() {
      return !isEmpty(pickBy(this.comments, (comments) => find(comments, { actual: true })));
    },
    hasDraftComments() {
      return !isEmpty(pickBy(this.comments, (comments) => find(comments, { draft: true })));
    },
    questionsWithComments() {
      return keys(pickBy(this.comments, (comments) => find(comments, ({actual, draft}) => actual || draft)));
    },
    risks() {
      return compact(concat(
          map(get(this.fields, ['section_2', 'sections', 'section_2_0', 'questions', 'question_2_0', 'values', 'CHECKBOX']), 'value'),
          map(get(this.fields, ['section_2', 'sections', 'section_2_in_territory', 'questions', 'question_2_in_territory', 'values', 'RADIO']), 'value'),
          map(get(this.fields, ['section_3', 'sections', 'section_3_0', 'questions', 'question_3_0', 'values', 'CHECKBOX']), 'value'),
      ));
    },
    hiddenKeys() {
      const risks = this.risks;
      const userType = compact(map(get(this.fields, ['section_user_type', 'questions', 'question_user_type', 'values', 'RADIO']), 'value'))
      const func = (questions) => {
        const temp = [];
        const exclude = ['question_3_0', 'question_user_type'];
        forEach(questions, (question, key) => {
          // Скрыть вопросы без комментариев
          if (this.simple && !includes(this.questionsWithComments, key)) {
            temp.push(key); return;
          }
          // Скрыть вопросы не подходящие под риски
          if (risks.length && question.risks.length && !find(risks, risk => includes(question.risks, risk))) {
            if (!includes(exclude, key)) temp.push(key); return;
          }
          // Скрыть вопросы не подходящие под организацию предпринимательства
          if (userType.length && !find(userType, userType => includes(question.userTypes, userType))) {
            if (!includes(exclude, key)) temp.push(key); return;
          }
        });
        return temp;
      };
      const result = [];
      forEach(this.fields, ({ sections, questions }, key) => {
        forEach(sections, ({ questions }, key) => {
          let hide = func(questions);
          if (hide.length === keys(questions).length) result.push(key);
          result.push(...hide);
        });

        let hide = func(questions);
        result.push(...hide);
        if (hide.length === keys(questions).length && !find(keys(sections), key => !includes(result, key))) result.push(key);
      });
      return result;
    },
    progress() {
      let total = 0;
      let current = 0;
      let completed = [];
      forEach(this.filtered(this.fields), (section) => {
        const progress = this.progressBySection(section);
        total += progress.total || 0;
        current += progress.current || 0;
        completed.push(...progress.completed);
      });
      return {
        total,
        current,
        completed,
      };
    },
    navigation() {
      return reverse(map(this.filtered(this.fields), ({ label, code }) => ({label, code})));
    }
  },
  methods: {
    ...mapActions({
      sendDecision: 'pkomain/sendDecision',
      updateCounterparty: 'pkomain/updateCounterparty',
      agreementCounterparty: 'pkomain/agreementCounterparty',
      fetchCommentList: 'pkomain/fetchCommentList',
    }),
    filtered(items) {
      return omit(items, this.hiddenKeys);
    },
    progressBySection(section) {
      let total = 0;
      let current = 0;
      const sections = this.filtered(section.sections);
      const questions = this.filtered(section.questions);
      const completed = [];
      if (!isEmpty(sections)) forEach(sections, (section) => {
        const progress = this.progressBySection(section);
        total += progress.total || 0;
        current += progress.current || 0;
        completed.push(...progress.completed);
      });
      total += keys(questions).length;
      const temp = keys(pickBy(questions, ({ type, values, rejections }) => {
        // Отказ от ответа
        if (get(rejections, ['BOOL', 0, 'value']) === '1' && get(rejections, ['TEXT', 0, 'value'])) return true;
        // // Не составные вопросы имеют ответ.
        if (get(values, [type, '0', 'value'])) return true;
        // Составные должны быть полностью заполнены
        if (!find(map(values, head), { value: '' })) return true;
        // Составные с BOOL могут быть заполнены как нет
        if (!type.indexOf('BOOL_') && get(values, ['BOOL', 0, 'value']) === '0') return true;
        //  Поля с доп данными валидируются иначе
        const tableKeys = ['carName', 'carNumber', 'carCategory', 'ownType', 'techDateEnd', 'documents', 'driverFio', 'driverLicenseCategory', 'driverLicenseDateEnd', 'driverMedicalCheckupDateEnd'];
        if (!type.indexOf('TABLE_') && !find(map(values, head), ({ additional }) => map(pick(additional, tableKeys), (value) => isEmpty(value) || !value).indexOf(true) + 1)) return true;
        return false;
      }));
      current += temp.length;
      completed.push(...temp);
      return { current, total, completed };
    },
    onSave() {
      const id = this.id;
      const items = this.fields;
      this.updateCounterparty({ id, items }).then(result => {
        if (result) {
          this.fetchCommentList({ id });
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Заявка успешно сохранена',
          });
        }
      });
    },
    onAgreement() {
      const id = this.id;
      this.agreementCounterparty({ id }).then((result) => {
        if (result) {
          this.$router.push({ name: 'pkomain/CounterpartyListView' });
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Заявка отправлена на согласование',
          });
        }
      });
    },
    onRework() {
      this.decision = STATUSES.REWORK;
      this.dialog = true;
    },
    onYes() {
      this.decision = STATUSES.YES;
      this.dialog = true;
    },
    onNo() {
      this.decision = STATUSES.NO;
      this.dialog = true;
    },
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
    onSubmitDecision(comment, notation) {
      const id = this.id;
      const decision = this.decision;
      this.sendDecision({ id, decision, comment, notation }).then((result) => {
        if (result) {
          this.$router.push({ name: 'pkomain/CounterpartyListView' });
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Решение по заявке принято',
          });
          this.dialog = false;
        }
      });
    },
    getIconForSection(code) {
      if (code === 'section_0') return 'mdi-file-document-multiple';
      if (code === 'section_1') return 'mdi-domain';
      if (code === 'section_2') return 'mdi-account-hard-hat';
      if (code === 'section_3') return 'mdi-car-info';
    },
  },
  watch: {
    diff: {
      immediate: true,
      handler: function(value) {
        if (value && !this.readonly) {
          this.timer = setInterval(this.onSave, 5 * 60 * 1000) // 5 мин
        } else {
          clearInterval(this.timer);
        }
        this.$route.meta.beforeEach = (to, from, next) => {
          if (from.name === 'pkomain/CounterpartyDetailView' && this.diff && !this.readonly) {
            this.$notify({
              type: 'warning',
              title: 'Подождите',
              text: 'Идет сохранение',
            });
            this.onSave();
          } else {
            clearInterval(this.timer);
            next();
          }
        };
      }
    },
    values: {
      handler: function() {
        this.onReset();
      }
    },
    dialog: {
      handler: function (value) {
        if (!value)
          this.decision = null;
      }
    },
    hiddenKeys: {
      handler(hiddenKeys) {
        if (~hiddenKeys.indexOf('section_2_0')) {
          const target = get(this.fields, 'section_2.sections.section_2_0.questions.question_2_0.values.CHECKBOX');
          if (target && !find(target, { value: 'bez_vypolneniya_rabot_povyshennoy_opasnosti' })) {
            set(this.fields, 'section_2.sections.section_2_0.questions.question_2_0.values.CHECKBOX', [{ date: '', id: '', type: 'CHECKBOX', value: 'bez_vypolneniya_rabot_povyshennoy_opasnosti'}]);
          }
        }
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  .loading {
    top: 0;
  }
}
.progress {
  position: absolute;
  bottom: 100%;
  right: 0;
  padding: 15px;
}
</style>
