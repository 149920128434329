<template>
  <div :class="$style.root">
    <VSpeedDial fixed bottom right direction="top" transition="slide-y-reverse-transition">
      <template v-slot:activator>
        <VBtn color="primary" fab depressed @click="fab = !fab">
          <VIcon>mdi-menu</VIcon>
        </VBtn>
      </template>
      <VBtn v-for="{code, label} in items" class="primary" small depressed :key="code" @click="onClick(code)">
        {{label}}
      </VBtn>
    </VSpeedDial>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    items: { type: Array },
  },
  data() {
    return {
      fab: false,
    }
  },
  methods: {
    onClick(code) {
      const elem = document.getElementById(code);
      if (elem) {
        const btn = elem.closest('button');
        if(!btn.classList.contains('v-expansion-panel-header--active')) btn.click();
        setTimeout(() => btn.scrollIntoView({ behavior: "smooth" }), 300);
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  :global {
    .v-speed-dial__list{
      align-items: flex-end;
    }
    .v-speed-dial--bottom {
      margin-bottom: 60px;
    }
  }
}
</style>
