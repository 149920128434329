<template>
  <div :class="$style.root">
    <VForm @submit.prevent="onSubmit">
      <VTextarea v-model="message" :label="label"  rows="3" outlined auto-grow hide-details />
      <div class="mt-3">
        <VBtn color="primary" depressed class="mr-3" :disabled="disabled || !message" @click="onSubmit">Сохранить</VBtn>
        <VBtn v-if="message" color="secondary" depressed class="mr-3" @click="onReset">Отмена</VBtn>
      </div>
    </VForm>
  </div>
</template>

<script>
export default {
  name: 'CommentForm',
  props: {
    disabled: { type: Boolean },
    label: { type: String, default: () => 'Комментарий' }
  },
  data() {
    return {
      message: '',
    }
  },
  methods: {
    onSubmit() {
      const message = this.message;
      this.$emit('submit', message);
      this.message = '';
    },
    onReset() {
     this.message = '';
     this.$emit('reset');
    }
  }
}
</script>

<style module lang="scss">
.root {

}
</style>
