<template>
  <div :class="$style.root">
    <VCheckbox
        class="mt-0"
        v-for="{ text, value } in options"
        v-model="values"
        :disabled="readonly || (only && value !== only)"
        :label="text"
        :value="value"
        :key="value"
        hide-details
    />
  </div>
</template>

<script>
import {filter, get, map, compact, keyBy, isEqual, find, partial, includes} from 'lodash-es';

export default {
  name: 'CheckboxListField',
  props: {
    value: { type: Array },
    options: { type: Array },
    readonly: { type: Boolean },
  },
  data(){
    return {
      values: compact(map(this.value, 'value')),
      exceptional: ['bez_vypolneniya_rabot_povyshennoy_opasnosti','raboty_provodyatsya_bez_ispolzovaniya_transporta'],
    }
  },
  computed: {
    only() {
      return find(this.values, partial(includes, this.exceptional));
    },
    allReadonly() {
      return filter(this.value, { readonly: true }).length === get(this.value, 'length', 0);
    },
  },
  watch: {
    only(value) {
      if (value) this.values = [value];
    },
    value(value) {
      const next = compact(map(value, 'value'));
      if (!isEqual(this.values, next)) this.values = next;
    },
    values(value) {
      const current = keyBy(this.value, 'value');
      this.$emit('input', map(value, (value) => get(current, value, { date: '', id: '', type: 'CHECKBOX', value})));
    }
  },
}
</script>

<style module lang="scss">
.root {}
</style>
