<template>
  <div :class="$style.root">
    <VCard flat>
      <VCardSubtitle>
        <div class="float-right">{{date}}</div>
        <b class="flex-grow-1">{{author}}</b>
      </VCardSubtitle>
      <VCardText>
        {{ message }}
      </VCardText>
      <VCardActions>
        <slot />
        <VChip v-if="actual" outlined small>Актуальный</VChip>
      </VCardActions>
    </VCard>
  </div>
</template>

<script>
export default {
  name: 'Comment',
  props: {
    date: { type: String },
    actual: { type: Boolean },
    author: { type: String },
    message: { type: String },
    draft: { type: Boolean },
  }
}
</script>

<style module lang="scss">
.root {
  border: 1px solid #dee2e6;
}
</style>
